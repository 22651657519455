import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { auth, db } from "./FirebaseConfig";
import { onAuthStateChanged } from "firebase/auth";

export const usePosts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      const fetchPosts = async () => {
        setLoading(true);
        try {
          const email = user.email;
          const snapshot = await getDocs(collection(db, email));
          const fetchedPosts = snapshot.docs.map((doc) => doc.data());
          const validPosts = fetchedPosts.filter((post) => post.aperture !== null);
          setPosts(validPosts);
        } catch (error) {
          console.error("Error fetching posts: ", error);
        }
        setLoading(false);
      };

      fetchPosts();
    }
  }, [user]);

  return { posts, loading, user };
};
