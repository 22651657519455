import "./App.css";
import { BrowserRouter, Route, Routes, Navigate, Link } from "react-router-dom";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { Layout, Button, Drawer } from "antd";
import { ReactComponent as Icon } from "./StomataScopeLogo.svg";
import Register from "./Register";
import Top from "./Top";
import Login from "./Login";
import Mypage from "./Mypage";
import Storage from "./Storage";
import Charts from "./Charts";
import SideMenu from "./SideMenu";
import UsersManual from "./UsersManual";
import Download from "./Download";
import ResetPassword from "./ResetPassword";
import BoundingBox from "./BoundingBox";
import ResultEdit from "./ResultEdit";
import Edit from "./Edit";
import { useState, useEffect } from "react";

const { Header, Sider, Content, Footer } = Layout;

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const headerStyle = {
    color: "rgb(104, 104, 104)",
    textAlign: "left",
    padding: 0,
    fontWeight: "700",
    height: 40,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  };
  const siderStyle = {
    textAlign: "right",
    lineHeight: "20px",
    color: "#fff",
    backgroundColor: "#696969",
    height: "100vh",
    fontWeight: "100",
  };

  return (
    
    <div className="main">
      <BrowserRouter>
        <Layout>
          {isMobile ? (
            <Drawer
              title="Menu"
              placement="left"
              onClose={() => setDrawerVisible(false)}
              visible={drawerVisible}
              bodyStyle={{ padding: 0 }}
              width="70%"
            >
              <SideMenu />
            </Drawer>
          ) : (
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              style={siderStyle}
              breakpoint="lg"
              collapsedWidth="0"
            >
              <SideMenu />
            </Sider>
          )}
          <Layout>
            <Header style={headerStyle} className="title">
              <>
                {!isMobile && (
                  <Button
                    type="text"
                    icon={collapsed ? <MenuOutlined /> : <CloseOutlined />}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                      marginLeft: 20,
                      width: 30,
                      height: 30,
                      float: "left",
                      color: "gray",
                    }}
                  />
                )}
                {isMobile && (
                  <Button
                    type="text"
                    icon={<MenuOutlined />}
                    onClick={() => setDrawerVisible(true)}
                    style={{
                      marginLeft: 20,
                      width: 30,
                      height: 30,
                      float: "left",
                      color: "gray",
                    }}
                  />
                )}
                <Icon
                  style={{
                    width: 20,
                    marginLeft: 20,
                    marginRight: 20,
                    color: "gray",
                  }}
                />
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    color: "gray",
                  }}
                >
                  Stomata Storage
                </div>
                <div style={{ flexGrow: 1 }}></div>
              </>
            </Header>
            <Content className="scrollable-container">
              <Routes>
                <Route path="/Top/" element={<Top />} />
                <Route path="/storage/" element={<Storage />} />
                <Route path="/charts/" element={<Charts />} />
                <Route path="/register/" element={<Register />} />
                <Route path="/login/" element={<Login />} />
                <Route path="/usersManual/" element={<UsersManual />} />
                <Route path="/" element={<Mypage />} />
                <Route path="/Terms/" element={<Mypage />} />
                <Route path="/ResetPassword/" element={<ResetPassword />} />
                <Route path="/Download/" element={<Download />} />
                <Route path="/BoundingBox/" element={<BoundingBox />} />
              </Routes>
            </Content>
            <Footer
              style={{
                textAlign: "center",
                height: 30,
                padding: 2,
              }}
            >
              <a
                href="https://happy-quality.jp/"
                target="_blank"
                rel="noreferrer"
                className="webLink"
              >
                Copyright2023 by Happy Quality Co.,Ltd. ver 1.0.0
              </a>
            </Footer>
          </Layout>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
