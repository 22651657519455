import React, { useEffect, useState, useRef } from "react";
import { collection, getDocs } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { Layout, Spin, Tree, Table, Button, Modal, Input, Form, theme } from "antd";
import { db, auth } from "./FirebaseConfig";

function Edit() {
    const [selectedPhotoInfo, setSelectedPhotoInfo] = useState(null); // 画像情報(userName, cultiverName)
    const [loading, setLoading] = useState(true); // ローディング状態
    const [user, setUser] = useState(null); // ユーザー情報
    const [treeData, setTreeData] = useState([]); // Treeのデータ
    const [selectedPhotoPath, setSelectedPhotoPath] = useState(null); // 選択されたPhotoPath
    const [boundingBoxes, setBoundingBoxes] = useState([]); // バウンディングボックスデータ
    const [newBoxData, setNewBoxData] = useState({ id: "", status: "", aperture: "", area: "" }); // 新規バウンディングボックス
    const [showModal, setShowModal] = useState(false); // モーダル表示状態
    const canvasRef = useRef(null); // Canvas参照

    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const { Sider, Content } = Layout;

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (user) {
            const fetchAndGroupData = async () => {
                try {
                    const email = user.email;
                    const snapshot = await getDocs(collection(db, email));
                    const fetchedPosts = snapshot.docs.map((doc) => doc.data());

                    // データをcultiverNameでグループ化
                    const groupedData = fetchedPosts.reduce((acc, post) => {
                        const { cultiverName, photopath, userName } = post;
                        if (!acc[cultiverName]) {
                            acc[cultiverName] = [];
                        }
                        acc[cultiverName].push({
                            title: photopath,
                            key: `${cultiverName}-${photopath}`,
                            userName,
                            cultiverName,
                            photopath,
                        });
                        return acc;
                    }, {});

                    const treeFormattedData = Object.entries(groupedData).map(([cultiverName, children]) => ({
                        title: cultiverName,
                        key: cultiverName,
                        children,
                    }));

                    setTreeData(treeFormattedData);
                } catch (error) {
                    console.error("Error fetching posts: ", error);
                }
            };

            fetchAndGroupData();
        }
    }, [user]);

    const handleTreeSelect = (selectedKeys, info) => {
        const selectedKey = selectedKeys[0];
        if (!selectedKey) return;

        const isPhotoPath = selectedKey.includes("-");
        if (isPhotoPath) {
            const { userName, cultiverName, photopath } = info.node;
            setSelectedPhotoPath(photopath);
            setSelectedPhotoInfo({ userName, cultiverName });
        }
    };

    useEffect(() => {
        if (selectedPhotoPath && selectedPhotoInfo) {
            const { userName, cultiverName } = selectedPhotoInfo;
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");

            const imageUrl = `https://firebasestorage.googleapis.com/v0/b/YOUR_PROJECT_ID.appspot.com/o/${encodeURIComponent(
                userName
            )}%2F${encodeURIComponent(cultiverName)}%2F${encodeURIComponent(selectedPhotoPath)}?alt=media`;

            const img = new Image();
            img.src = imageUrl;

            img.onload = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                const aspectRatio = img.width / img.height;
                const canvasAspect = canvas.width / canvas.height;

                let drawWidth, drawHeight, offsetX, offsetY, scaleX, scaleY;

                if (aspectRatio > canvasAspect) {
                    drawWidth = canvas.width;
                    drawHeight = canvas.width / aspectRatio;
                    offsetX = 0;
                    offsetY = (canvas.height - drawHeight) / 2;
                } else {
                    drawWidth = canvas.height * aspectRatio;
                    drawHeight = canvas.height;
                    offsetX = (canvas.width - drawWidth) / 2;
                    offsetY = 0;
                }

                // スケール比率の計算
                scaleX = drawWidth / img.width;
                scaleY = drawHeight / img.height;

                ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);

                // バウンディングボックスをスケールとオフセットに合わせて調整
                const adjustedBoxes = boundingBoxes.map((box) => ({
                    ...box,
                    x: box.x * scaleX + offsetX,
                    y: box.y * scaleY + offsetY,
                    width: box.width * scaleX,
                    height: box.height * scaleY,
                }));

                setBoundingBoxes(adjustedBoxes); // 調整後のボックスをセット
            };

            img.onerror = () => {
                console.error("Failed to load image:", imageUrl);
            };
        }
    }, [selectedPhotoPath, selectedPhotoInfo, boundingBoxes]);


    useEffect(() => {
        if (selectedPhotoPath && boundingBoxes.length > 0) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");

            const drawBoundingBoxes = () => {
                boundingBoxes.forEach((box) => {
                    ctx.strokeStyle = "red";
                    ctx.lineWidth = 2;
                    ctx.strokeRect(box.x, box.y, box.width, box.height);

                    // ラベルを描画
                    ctx.fillStyle = "white";
                    ctx.font = "14px Arial";
                    ctx.fillText(`ID: ${box.id}`, box.x + 4, box.y + 14);
                    ctx.fillText(`Status: ${box.status}`, box.x + 4, box.y + 28);
                });
            };

            drawBoundingBoxes();
        }
    }, [boundingBoxes]);

    const handleModalOk = () => {
        setBoundingBoxes((prev) => [...prev, newBoxData]);
        setNewBoxData({ id: "", status: "", aperture: "", area: "" });
        setShowModal(false);
    };

    const handleModalCancel = () => {
        setShowModal(false);
    };

    return (
        <div
            style={{
                padding: 24,
                background: colorBgContainer,
                height: "98%",
            }}
        >
            {loading ? (
                <div className="spin">
                    <Spin size="large" tip="Loading" />
                </div>
            ) : (
                <Layout style={{ height: "100vh" }}>
                    {/* 左: Tree */}
                    <Sider width="15%" theme="light" style={{ padding: "16px" }}>
                        <Tree
                            treeData={treeData}
                            onSelect={handleTreeSelect}
                            defaultExpandAll
                            style={{ height: "100%", overflowY: "auto" }}
                        />
                    </Sider>

                    {/* 中央: Canvas */}
                    <Content style={{ flex: "70%", padding: "0px", position: "relative" }}>
                        <div style={{ flex: 1, padding: "16px", border: "1px solid #ddd" }}>
                            <h3>Selected Photo: {selectedPhotoPath || "None"}</h3>
                            <canvas ref={canvasRef} width={960} height={540} style={{ border: "1px solid #ddd" }} />
                        </div>
                    </Content>

                    {/* 右: Table */}
                    <Sider width="15%" theme="light" style={{ padding: "16px" }}>
                        <h3>Bounding Box Details</h3>
                        <Table
                            dataSource={boundingBoxes.map((box, index) => ({ ...box, key: index }))}
                            columns={[
                                { title: "ID", dataIndex: "id", key: "id" },
                                { title: "Status", dataIndex: "status", key: "status" },
                                { title: "Aperture", dataIndex: "aperture", key: "aperture" },
                                { title: "Area", dataIndex: "area", key: "area" },
                            ]}
                            pagination={false}
                        />
                        <Button type="dashed" style={{ marginTop: "16px" }} onClick={() => setShowModal(true)}>
                            Add Bounding Box
                        </Button>
                    </Sider>

                    {/* モーダル */}
                    <Modal
                        title="Add Bounding Box"
                        visible={showModal}
                        onOk={handleModalOk}
                        onCancel={handleModalCancel}
                    >
                        <Form>
                            <Form.Item label="ID">
                                <Input
                                    value={newBoxData.id}
                                    onChange={(e) => setNewBoxData({ ...newBoxData, id: e.target.value })}
                                />
                            </Form.Item>
                            <Form.Item label="Status">
                                <Input
                                    value={newBoxData.status}
                                    onChange={(e) => setNewBoxData({ ...newBoxData, status: e.target.value })}
                                />
                            </Form.Item>
                            <Form.Item label="Aperture">
                                <Input
                                    value={newBoxData.aperture}
                                    onChange={(e) => setNewBoxData({ ...newBoxData, aperture: e.target.value })}
                                />
                            </Form.Item>
                            <Form.Item label="Area">
                                <Input
                                    value={newBoxData.area}
                                    onChange={(e) => setNewBoxData({ ...newBoxData, area: e.target.value })}
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
                </Layout>
            )}
        </div>
    );
}

export default Edit;
