import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { InputNumber, Col, Table, Button, Divider, Popover, theme, Switch, Spin, Slider } from "antd";
import { db, auth } from "./FirebaseConfig";
import { SketchPicker } from "react-color";

import { CheckOutlined, CloseOutlined, BlockOutlined } from '@ant-design/icons';
import { onAuthStateChanged } from "firebase/auth";
import "./App.css";

import { usePosts } from "./usePosts";

function BoundingBox() {
  const { posts, loading, user } = usePosts();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [boundingBoxes, setBoundingBoxes] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1); // 初期ズームレベル
  const [currentPosition, setCurrentPosition] = useState({ x: 0, y: 0 }); // ドラッグの位置
  const [isDragging, setIsDragging] = useState(false); // ドラッグ状態
  const [startDragPosition, setStartDragPosition] = useState({ x: 0, y: 0 });
  const [openColor, setOpenColor] = useState("#ff0000"); // 初期色: Open (赤)
  const [closeColor, setCloseColor] = useState("#0000ff"); // 初期色: Close (青)
  const actualWidth = 3840; // 画像の実サイズ
  const actualHeight = 2160;
  const displayWidth = 1000; // 表示サイズ
  const displayHeight = (actualHeight / actualWidth) * displayWidth;
  const scaleX = actualWidth / displayWidth; // 横方向スケール
  const scaleY = actualHeight / displayHeight; // 縦方向スケール
  const [hoveredBoxKey, setHoveredBoxKey] = useState(null); // ホバー中のボックス
  const [drawBoundingBox, setDrawBoundingBox] = useState(true);
  const [drawText, setDrawText] = useState(true);
  const [drawScaleBar, setDrawScaleBar] = useState(true);
  const [boxShadowWidth, setBoxShadowWidth] = useState(0.1);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handlePhotoClick = (photo) => {
    if (!photo.results || photo.results.length === 0) return;
    setSelectedPhoto(photo);
    console.log(selectedPhoto)
    setBoundingBoxes(
      photo.results.map((item, index) => ({
        key: index,
        x: item.location.left,
        y: item.location.top,
        width: item.location.right - item.location.left,
        height: item.location.bottom - item.location.top,
        title: item.title || "Unknown",
        aperture: item.aperture,
        long: item.axisLong,
        short: item.axisShort,
      }))
    );
  };

  // ドラッグ開始
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartDragPosition({ x: e.clientX - currentPosition.x, y: e.clientY - currentPosition.y });
  };

  // ドラッグ中
  const handleMouseMove = (e) => {
    if (isDragging) {
      const x = e.clientX - startDragPosition.x;
      const y = e.clientY - startDragPosition.y;
      setCurrentPosition({ x, y });
    }
  };

  // ドラッグ終了
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // ホイールでズーム
  const handleWheel = (e) => {
    e.preventDefault();
    const delta = e.deltaY > 0 ? -0.1 : 0.1; // ホイールの方向でズーム変更
    setZoomLevel((prevZoom) => Math.min(3, Math.max(0.5, prevZoom + delta)));
  };
  const handleOpenColorChange = (color) => {
    setOpenColor(color.hex);
  };

  const handleCloseColorChange = (color) => {
    setCloseColor(color.hex);
  };
  const downloadWithBoundingBox = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = actualWidth;
    canvas.height = actualHeight;

    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = `https://firebasestorage.googleapis.com/v0/b/stomatascope-fdf90.appspot.com/o/${encodeURIComponent(
      selectedPhoto.userName
    )}%2F${encodeURIComponent(selectedPhoto.cultiverName)}%2F${encodeURIComponent(
      selectedPhoto.photopath
    )}?alt=media&token=bb06e309-ca5b-4b6b-9bdd-7ea80efa4ced`;

    image.onload = () => {
      ctx.drawImage(image, 0, 0, actualWidth, actualHeight);

      if (drawBoundingBox) {
        boundingBoxes.forEach((box) => {
          const color = box.title === "open" ? openColor : closeColor;
          ctx.strokeStyle = color;
          ctx.lineWidth = boxShadowWidth * 5;
          ctx.strokeRect(box.x, box.y, box.width, box.height);

          if (drawText) {
            ctx.fillStyle = color;
            ctx.font = "10px Arial";
            ctx.fillText("ID:" + (box.key), box.x + 5, box.y);
            ctx.fillText(box.title, box.x + 5, box.y + box.height + 10);
            ctx.fillText("aperture:" + box.aperture.toFixed(2) + "µm", box.x + 5, box.y + box.height + 20);
            ctx.fillText("axisShort:" + box.short.toFixed(2) + "pixel", box.x + 5, box.y + box.height + 30);
            ctx.fillText("axisLong:" + box.long.toFixed(2) + "pixel", box.x + 5, box.y + box.height + 40);
          }
        });
      }

      if (drawScaleBar) {
        const scaleBarLength = 10 / 0.45;
        const scaleBarHeight = 1;
        const margin = 20;

        ctx.fillStyle = "white";
        ctx.fillRect(margin, actualHeight - margin - scaleBarHeight, scaleBarLength, scaleBarHeight);

        ctx.strokeStyle = "black";
        ctx.lineWidth = 0.1;
        ctx.strokeRect(margin, actualHeight - margin - scaleBarHeight, scaleBarLength, scaleBarHeight);

        ctx.fillStyle = "black";
        ctx.font = "20px Arial";
        ctx.textAlign = "center";
        ctx.fillText(
          "10 µm",
          margin + scaleBarLength / 2,
          actualHeight - margin - scaleBarHeight - 5
        );
      }

      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/jpeg");
      link.download = selectedPhoto.photopath;
      link.click();
    };
  };
  // 画像とバウンディングボックスを描画
  return (
    <div
      style={{
        padding: 24,
        background: colorBgContainer,
        height: "98%",
      }}
    >
      {loading ? (
        <div className="spin">
          <Spin size="large" tip="Loading" />
        </div>
      ) : (
        <>
          {!user ? (
            <Navigate to={"/"} />
          ) : (
            <>
              <div style={{ display: "flex", flexDirection: "row", height: "88vh" }}>
                {/* 左側: Photo List */}
                <div style={{ flex: 1, overflowY: "auto", borderRight: "1px solid #ddd" }}>
                  <h3 style={{ textAlign: "center" }}>Photo List</h3>
                  <Table
                    dataSource={posts}
                    columns={[
                      {
                        title: "ファイル名",
                        dataIndex: "photopath",
                        key: "photopath",
                        sorter: (a, b) => a.photopath.localeCompare(b.photopath),
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (text, record) => (
                          <span
                            onClick={() => handlePhotoClick(record)}
                            style={{
                              cursor: "pointer",
                              color: selectedPhoto === record ? "blue" : "black",
                            }}
                          >
                            {text}
                          </span>
                        ),
                      },
                      {
                        title: "品種名",
                        dataIndex: "cultiverName",
                        key: "cultiverName",
                        sorter: (a, b) => a.cultiverName.localeCompare(b.cultiverName),
                        sortDirections: ["ascend", "descend", "ascend"],
                      },
                    ]}
                    rowKey={(record) => record.photopath}
                    pagination={false}
                  />
                </div>

                {/* 中央: 画像とバウンディングボックス */}
                <div
                  style={{
                    flex: 4,
                    position: "relative",
                    overflow: "hidden",
                    backgroundColor: "#000",
                    cursor: isDragging ? "grabbing" : "grab",
                  }}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                  onWheel={handleWheel}
                >
                  {/* 設定フォーム */}
                  <div
                    style={{
                      position: "absolute",
                      top: "20px",
                      left: "20px",
                      zIndex: 10, // Canvasより前面に配置
                      background: "rgba(255, 255, 255, 0.9)", // 半透明の背景
                      padding: "15px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      width: "300px",
                    }}
                    onMouseDown={(e) => e.stopPropagation()} // フォーム内でイベント伝播を停止

                  >
                    <div style={{ marginBottom: "10px" }}>
                      <span>Draw Bounding Box: </span>
                      <Switch
                        checked={drawBoundingBox}
                        onChange={(checked) => setDrawBoundingBox(checked)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                      />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <span>Draw Text: </span>
                      <Switch
                        checked={drawText}
                        onChange={(checked) => setDrawText(checked)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                      />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <span>Draw Scale Bar: </span>
                      <Switch
                        checked={drawScaleBar}
                        onChange={(checked) => setDrawScaleBar(checked)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                      />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <span>Open Color: </span>
                      <Popover
                        content={<SketchPicker color={openColor} onChange={handleOpenColorChange} />}
                        title="Select Open Color"
                        trigger="click"
                      >
                        <Button style={{ backgroundColor: openColor, color: "#fff" }}>Pick Color</Button>
                      </Popover>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <span>Close Color: </span>
                      <Popover
                        content={<SketchPicker color={closeColor} onChange={handleCloseColorChange} />}
                        title="Select Close Color"
                        trigger="click"
                      >
                        <Button style={{ backgroundColor: closeColor, color: "#fff" }}>Pick Color</Button>
                      </Popover>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      LineWidth:{" "}{boxShadowWidth}
                      <Slider
                        min={0}
                        max={1}
                        value={boxShadowWidth}
                        onChange={(value) => setBoxShadowWidth(value)}
                        step={0.1}
                      />

                    </div>

                    <div>
                      <Button disabled={!selectedPhoto} type="primary" style={{ marginTop: "10px" }} onClick={downloadWithBoundingBox}>
                        Export Image
                      </Button>
                    </div>

                  </div>

                  {selectedPhoto && (
                    <div
                      style={{
                        position: "relative",
                        width: `${displayWidth}px`,
                        height: `${displayHeight}px`,
                        transform: `translate(${currentPosition.x}px, ${currentPosition.y}px) scale(${zoomLevel})`,
                        transformOrigin: "center",
                      }}
                    >
                      {selectedPhoto && (
                        <div
                          style={{
                            position: "absolute",
                            bottom: "10px", // 画像の下から 10px
                            left: "10px", // 画像の左から 10px
                            zIndex: 1000, // 他の要素より前面に表示
                            display: "flex",
                            alignItems: "center",
                          }}
                        />
                      )}
                      <img
                        src={`https://firebasestorage.googleapis.com/v0/b/stomatascope-fdf90.appspot.com/o/${encodeURIComponent(
                          selectedPhoto.userName
                        )}%2F${encodeURIComponent(selectedPhoto.cultiverName)}%2F${encodeURIComponent(
                          selectedPhoto.photopath
                        )}?alt=media&token=bb06e309-ca5b-4b6b-9bdd-7ea80efa4ced`}
                        alt="Selected"
                        style={{
                          width: `${displayWidth}px`,
                          height: `${displayHeight}px`,
                          objectFit: "contain",
                        }}
                        onLoad={() => setImageLoaded(true)}
                        draggable="false"
                      />
                      {imageLoaded && drawBoundingBox &&
                        boundingBoxes.map((box) => (
                          <div
                            key={box.key}
                            onMouseEnter={() => setHoveredBoxKey(box.key)} // ホバー開始
                            onMouseLeave={() => setHoveredBoxKey(null)} // ホバー終了
                            style={{
                              position: "absolute",
                              top: box.y / scaleY,
                              left: box.x / scaleX,
                              width: (box.width / scaleX) / zoomLevel,
                              height: (box.height / scaleY) / zoomLevel,
                              border: "none", // ボーダーを無効化
                              boxShadow: `0 0 0 ${boxShadowWidth}px ${box.title === "open" ? openColor : closeColor}`,
                              transform: `scale(${zoomLevel})`,
                              transformOrigin: "top left",
                            }}
                          >
                            <div style={{ position: "relative", width: "100%", height: "100%" }}>
                              {/* ID: 左上 */}
                              <div
                                style={{
                                  position: "absolute",
                                  top: -1, // ボックスの上端
                                  left: 0, // ボックスの左端
                                  color: "black",
                                  fontSize: "8px", // 適切なサイズに調整
                                  whiteSpace: "nowrap",
                                  fontSize: "1px",
                                  color: box.title === "open" ? openColor : closeColor, // 条件によって色を変更
                                }}
                              >
                                <div>
                                  ID:{box.key + 1}
                                </div>
                              </div>

                            </div>
                            {drawText && (
                              <div style={{ position: "relative", width: "100%", height: "100%" }}>

                                {/* その他の情報: 左下 */}
                                <div
                                  style={{
                                    position: "absolute",
                                    lineHeight: "1px",
                                    marginTop: "0px",
                                    color: "black",
                                    fontSize: "8px", // 適切なサイズに調整
                                    whiteSpace: "nowrap",
                                    fontSize: "1px",
                                    color: box.title === "open" ? openColor : closeColor, // 条件によって色を変更
                                  }}
                                >
                                  <div>
                                    {box.title}
                                  </div>
                                  <div>
                                    aperture:{box.aperture.toFixed(2)} μm
                                  </div>
                                  <div>
                                    axisShort: {box.short.toFixed(2)} pixel
                                  </div>
                                  <div>
                                    axisLong: {box.long.toFixed(2)} pixel
                                  </div>
                                </div>
                              </div>


                            )}
                            {/* ホバー中のボックスのみ情報を表示 */}
                            {hoveredBoxKey === box.key && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "0%",
                                  left: "0%",
                                  transform: "translateX(-50%)",
                                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                                  color: "white",
                                  padding: "2px",
                                  borderRadius: "2px",
                                  fontSize: "4px",
                                  textAlign: "center",
                                  lineHeight: "1",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <div>
                                  <strong>ID:</strong> {box.key}
                                </div>

                                <>
                                  <div>
                                    <strong>aperture:</strong> {(box.aperture).toFixed(2)} μm
                                  </div>
                                  <div>
                                    <strong>axisShort:</strong> {box.short.toFixed(2)} pixel
                                  </div>
                                  <div>
                                    <strong>axisLong:</strong> {box.long .toFixed(2)} pixel
                                  </div>

                                </>

                              </div>
                            )}
                          </div>

                        ))}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default BoundingBox;
