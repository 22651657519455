import React from 'react';
import { Card, Collapse, Typography, List } from 'antd';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

// 警告の種類と説明
const warningLevels = [
  { type: '危険', color: 'red', description: '死亡または重症などを追う可能性が切迫して想定される内容です。' },
  { type: '警告', color: 'orange', description: '死亡または重症などを追う可能性が想定される内容です。' },
  { type: '注意', color: 'blue', description: '障害を負う可能性または物的損害の発生が想定される内容です。' },
];

// 具体的な注意事項
const safetyInstructions = [
  '高所や足場の悪い危険な場所では使用しないでください。落下、転倒の原因となります。',
  'お車などの走行中に操作しないでください。交通事故の原因となります。',
  '医療用電気機器の近くでは使用しないでください。ペースメーカーや医療用機器に影響を与える恐れがあります。',
  '濡れた手や汚れた手で本機を使用しないでください。火災や感電、故障の原因となります。',
  '本機のUSB-CはAndroidスマートフォン専用です。他の機器への接続は故障の原因となります。',
  '故障や破損した場合は直ちに使用を中止してください。そのまま使用すると火災や感電の原因となります。',
  '海外では使用しないでください。本機は日本国内仕様です。',
  '夏の炎天下、真冬、湿度が高い場所ではできるだけ使用を控えてください。性能の劣化や本体の変形を招く恐れがあります。',
  '植物体の損傷や病害虫の発生に関して、弊社は一切の責任を負いません。',
  'USB-Cケーブルや電源コードを無理に曲げたり、加工したりしないでください。感電や火災の原因となります。',
  '分解や改造は絶対にしないでください。発火やケガの原因となります。',
  '照明点灯時は発光面を直視しないでください。目に悪影響を及ぼす恐れがあります。',
  '小さいお子様や手が不自由な方は、可動部分での挟み込みに十分注意してください。',
];

// コンポーネント
function SafetyInstructions() {
  return (
    <Card
      title="安全上の注意"
      bordered
      style={{ margin: '20px', boxShadow: '0 2px 8px rgba(0,0,0,0.15)' }}
    >
      {/* 警告の種類 */}
      <Title level={4}>警告の種類</Title>
      <List
        itemLayout="horizontal"
        dataSource={warningLevels}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={<span style={{ color: item.color, fontWeight: 'bold' }}>{item.type}</span>}
              description={item.description}
            />
          </List.Item>
        )}
      />

      {/* 具体的な注意事項 */}
      <Title level={4} style={{ marginTop: '20px' }}>
        具体的な注意事項
      </Title>
      <Collapse>
        {safetyInstructions.map((instruction, index) => (
          <Panel header={`注意 ${index + 1}`} key={index}>
            <Paragraph>{instruction}</Paragraph>
          </Panel>
        ))}
      </Collapse>
    </Card>
  );
}

export default SafetyInstructions;
